import React, { useEffect } from 'react';
import request_sse, { heatflow_state, set_topics } from './RealtimeData/SSE_index';
import { initialize_heatflow_state, initialize_heatflow_timer } from './HeatFlowRealtime';
import { exists } from './SVGUtils';

import { ReactComponent as PHDiagram_SVG } from '../images/svg/ph_diagram.svg';

export default function PHDiagram() {
    function calculateLogY(pressure, yMin, yMax, pMin = 0.5, pMax = 80) {
        const logPressure = Math.log10(pressure);
        const logPMin = Math.log10(pMin);
        const logPMax = Math.log10(pMax);

        // Perform the logarithmic mapping
        const yPos = yMax - ((logPressure - logPMin) / (logPMax - logPMin)) * (yMax - yMin);

        return yPos;
    }

    function calculateXPos(xPos) {
        const xMin = 55;
        const xMid = 150; // The x position where the slope changes
        const smallPixelBias = 0.3;
        const bigPixelBias = 1.826666666666667;

        if (xPos < xMid) {
            return xMin + xPos * smallPixelBias;
        }
        else {
            const diff = xPos - xMid;
            return xMin + (xPos - diff) * smallPixelBias + (diff) * bigPixelBias;
        }
    }

    function calculateYPos(yPos) {
        const yMax = 375;
        const yMin = 15;

        return yMax - calculateLogY(yPos, yMax, yMin) + yMin;
    }

    function draw_phdiagram(state) {
        const EXPIRATION_DELAY_MS = 10000;
        const EXPIRATION_DATE = new Date(Date.now() - EXPIRATION_DELAY_MS);

        if (!exists('PHDiagram_SVG')) {
            const points = [
                [ state.measurements.enthalpy3, state.measurements.pressure1 ],
                [ state.measurements.enthalpy1, state.measurements.pressure1 ],
                [ state.measurements.enthalpy2, state.measurements.pressure2 ],
                [ state.measurements.enthalpy3, state.measurements.pressure3 ],
            ];

            const elements = [
                { 'id': 'point1', 'x': points[0][0], 'y': points[0][1] },
                { 'id': 'point2', 'x': points[1][0], 'y': points[1][1] },
                { 'id': 'point3', 'x': points[2][0], 'y': points[2][1] },
                { 'id': 'point4', 'x': points[3][0], 'y': points[3][1] },
            ];

            elements.forEach((element) => {
                const point = document.getElementById(element.id);

                const x = calculateXPos(element.x.value);
                const y = calculateYPos(element.y.value);

                point.setAttribute('cx', x);
                point.setAttribute('cy', y);

                if (point.timestamp < EXPIRATION_DATE) {
                    point.classList.add('-expired');
                }
                else {
                    point.classList.remove('-expired');
                }
            });

            const x1 = calculateXPos(points[0][0].value);
            const y1 = calculateYPos(points[0][1].value);
            const x2 = calculateXPos(points[1][0].value);
            const y2 = calculateYPos(points[1][1].value);
            const x3 = calculateXPos(points[2][0].value);
            const y3 = calculateYPos(points[2][1].value);
            const x4 = calculateXPos(points[3][0].value);
            const y4 = calculateYPos(points[3][1].value);

            const line = document.getElementById('line');
            line.setAttribute('points', `${x1},${y1} ${x2},${y2} ${x3},${y3} ${x4},${y4} ${x1},${y1}`);
        }
    }

    useEffect(() => {
        set_topics();
        initialize_heatflow_state();
        initialize_heatflow_timer(() => {
            draw_phdiagram(heatflow_state);
        });
        request_sse();
    }, []);

    return (
        <main id="ph_diagram_page" className="no_padding svg">
            <PHDiagram_SVG />
        </main>
    );
}
