import React, { useEffect } from 'react';

import request_sse, { heatflow_state, set_topics } from './RealtimeData/SSE_index';
import { exists, formatNumber } from './SVGUtils';

import { ReactComponent as HeatFlow_SVG } from '../images/svg/heatflow.svg';
import { initialize_heatflow_state, initialize_heatflow_timer } from './HeatFlowRealtime';

/**
 * Display current state in SVG
 */
export function draw_heatflow(state) {
    const EXPIRATION_DELAY_MS = 10000;
    const EXPIRATION_DATE = new Date(Date.now() - EXPIRATION_DELAY_MS);

    if (!exists('HeatFlow_SVG')) {
        return;
    }

    const knownModes = [ 'C51', 'C59', 'C55', 'C15', 'C73', 'C85' ];

    /**
     * name = name of the arrow
     * measurement = name of the measurement the arrow is based on
     * temperatures = names of the measurements that are used to determine the color of the arrow
     * visibleInModes = modes in which the arrow is visible
     */
    const arrows = [
        { 'name': 'qdot2', 'measurement': 'Qdot_ambient_LTrad', 'temperatures': [ 'T_C_LTR1', 'T_C_LTR2' ], 'visibleInModes': [ 'C51', 'C59', 'C55', 'C15', 'C73', 'C85' ] },
        { 'name': 'qdot15', 'measurement': 'Qdot_ambient_LTrad', 'temperatures': [ 'T_C_LTR1', 'T_C_LTR2' ], 'visibleInModes': [ 'C73' ] },
        { 'name': 'qdot6', 'measurement': 'Qdot_LTrad_PTU', 'temperatures': [ 'T_C_uCHL', 'T_C_dCHL' ], 'visibleInModes': [ 'C51', 'C59', 'C55' ] },
        { 'name': 'qdot12', 'measurement': 'Qdot_LTrad_PTU', 'temperatures': [ 'T_C_uCHL', 'T_C_dCHL' ], 'visibleInModes': [ 'C15' ] },
        { 'name': 'qdot17', 'measurement': 'Qdot_LTrad_PTU', 'temperatures': [ 'T_C_uCHL', 'T_C_dCHL' ], 'visibleInModes': [ 'C85' ] },
        { 'name': 'qdot4', 'measurement': 'Qdot_PTU_HVAC', 'temperatures': [ 'T_C_uLCC', 'T_C_dLCC' ], 'visibleInModes': [ 'C59' ] },
        { 'name': 'qdot13', 'measurement': 'Qdot_PTU_HVAC', 'temperatures': [ 'T_C_uLCC', 'T_C_dLCC' ], 'visibleInModes': [ 'C51', 'C15', 'C73', 'C85' ] },
        { 'name': 'qdot10', 'measurement': 'Qdot_HVAC_cabin', 'temperatures': [ 'T_C_uHC', 'T_C_dHC' ], 'visibleInModes': [ 'C59', 'C55' ] },
        { 'name': 'qdot11', 'measurement': 'Qdot_HVAC_cabin', 'temperatures': [ 'T_C_uHC', 'T_C_dHC' ], 'visibleInModes': [ 'C51', 'C59', 'C55', 'C15', 'C73', 'C85' ] },
        { 'name': 'qdot1', 'measurement': 'Qdot_MTrad_ambient', 'temperatures': [ 'T_C_MTR1', 'T_C_MTR2' ], 'visibleInModes': [ 'C51', 'C59', 'C55', 'C15', 'C73' ] },
        { 'name': 'qdot9', 'measurement': 'Qdot_MTrad_ambient', 'temperatures': [ 'T_C_MTR1', 'T_C_MTR2' ], 'visibleInModes': [ 'C55' ] },
        { 'name': 'qdot7', 'measurement': 'Qdot_cabin_HVAC', 'temperatures': [ 'T_C_uCC', 'T_C_dCC' ], 'visibleInModes': [ 'C51', 'C59', 'C55', 'C15', 'C73' ] },
        { 'name': 'qdot8', 'measurement': 'Qdot_EDU_MTrad', 'temperatures': [ 'T_C_uEDU', 'T_C_dEDU' ], 'visibleInModes': [] },
        { 'name': 'qdot3', 'measurement': 'Qdot_EDU_MTrad', 'temperatures': [ 'T_C_uEDU', 'T_C_dEDU' ], 'visibleInModes': [ 'C51', 'C59', 'C55', 'C15' ] },
        { 'name': 'qdot5', 'measurement': 'Qdot_BAT_LTrad', 'temperatures': [ 'T_C_uBAT', 'T_C_dDCDC' ], 'visibleInModes': [ 'C51', 'C59', 'C55' ] },
        { 'name': 'qdot16', 'measurement': 'Qdot_BAT_LTrad', 'temperatures': [ 'T_C_uBAT', 'T_C_dDCDC' ], 'visibleInModes': [ 'C73', 'C85' ] },
        { 'name': 'qdot18', 'measurement': 'Qdot_cabin_HVAC', 'temperatures': [ 'T_C_uCC', 'T_C_dCC' ], 'visibleInModes': [ 'C73' ] },
        { 'name': 'pel', 'measurement': 'Com_pwrCmprInp', 'temperatures': [], 'visibleInModes': [ 'C51', 'C59', 'C55', 'C15', 'C73', 'C85' ] },
    ];

    const temperatureArrows = [
        {
            'name': 't_a_hc',
            'temperatures': [ 'T_A_HC1', 'T_A_HC2', 'T_A_HC3', 'T_A_HC4', 'T_A_HC5', 'T_A_HC6', 'T_A_HC7', 'T_A_HC8' ],
            'visibleInModes': [ 'C51', 'C59', 'C55', 'C15', 'C73', 'C85' ],
        },
        {
            'name': 't_a_cc',
            'temperatures': [ 'T_A_CC1', 'T_A_CC2', 'T_A_CC3', 'T_A_CC4', 'T_A_CC5', 'T_A_CC6', 'T_A_CC7' ],
            'visibleInModes': [ 'C51', 'C59', 'C55', 'C15', 'C73' ],
        },
    ];

    let mode = getMode(state);
    updateModeLabel(knownModes, mode);
    if (!knownModes.includes(mode)) {
        /*
         * if we are in an 'exotic' mode, i.e. an unexpected mode,
         * let us treat it as if it were mode C85
         */
        mode = 'C85';
    }

    updateTemperatureTextBox('text_t_ambient', 'EnvT_t', state, EXPIRATION_DATE);
    updateTemperatureTextBox('text_t_cabin', 'Com_tCbn', state, EXPIRATION_DATE);
    updateTemperatureTextBoxAvarage('text_t_c_mtr', 'T_C_MTR1', 'T_C_MTR2', state, EXPIRATION_DATE);
    updateTemperatureTextBoxAvarage('text_t_c_ltr', 'T_C_LTR1', 'T_C_LTR2', state, EXPIRATION_DATE);
    updateTemperatureTextBoxAvarage('text_t_c_edu', 'T_C_uEDU', 'T_C_dEDU', state, EXPIRATION_DATE);
    updateTemperatureTextBoxAvarage('text_t_c_lcc', 'T_C_uLCC', 'T_C_dLCC', state, EXPIRATION_DATE);
    updateTemperatureTextBoxAvarage('text_t_c_chl', 'T_C_uCHL', 'T_C_dCHL', state, EXPIRATION_DATE);
    updateTemperatureTextBoxAvarage('text_t_c_bat', 'T_C_uBAT', 'T_C_dDCDC', state, EXPIRATION_DATE);
    updateTemperatureTextBoxAvarage('text_t_c_hc', 'T_C_uHC', 'T_C_dHC', state, EXPIRATION_DATE);
    updateTemperatureTextBoxAvarage('text_t_c_cc', 'T_C_uCC', 'T_C_dCC', state, EXPIRATION_DATE);

    temperatureArrows.forEach((temperatureArrow) => {
        updateTemperatureArrows(temperatureArrow, state, EXPIRATION_DATE, mode);
    });

    for (let i = 0; i < arrows.length; i++) {
        const arrow = arrows[i];

        const arrowId = `arrow_${arrow.name}`;
        const arrowElem = exists(arrowId);
        if (arrowElem) {
            updateArrow(arrowElem, arrow, state, EXPIRATION_DATE, mode);
        }

        const textId = `text_${arrow.name}`;
        const textElem = exists(textId);
        if (textElem) {
            updateText(textElem, arrow, state, EXPIRATION_DATE, mode);
        }
    }
}

function getMode(state) {
    const modeSignal = state?.measurements?.ThermLvl_stVlvMod;
    if (modeSignal === undefined || modeSignal === null || modeSignal.value === undefined || modeSignal.value === null) {
        return '';
    }

    return `C${modeSignal.value}`;
}

function updateModeLabel(knownModes, mode) {
    let modeString = mode;
    if (modeString === '') {
        modeString = 'unknown';
    }
    else if (!knownModes.includes(modeString)) {
        modeString += ' (unexpected mode)';
    }

    const modeLabel = exists('heatflow-mode-label');
    modeLabel.innerText = modeString;
}

function updateTemperatureTextBox(id, measurementName, state, EXPIRATION_DATE) {
    const textElem = exists(id);

    const unit = (textElem.dataset.unit ? textElem.dataset.unit : '');
    let value = state.measurements[measurementName].value;
    if (value === undefined || value === null) {
        value = '?';
    }
    else {
        value = formatNumber(value, textElem);
    }
    textElem.innerHTML = `${value}${unit}`;

    textElem.classList.toggle('expired', state.measurements[measurementName].timestamp < EXPIRATION_DATE);
}

function updateTemperatureTextBoxAvarage(id, measurementName1, measurementName2, state, EXPIRATION_DATE) {
    const textElem = exists(id);

    const unit = (textElem.dataset.unit ? textElem.dataset.unit : '');
    let value = state.measurements[measurementName1].value;
    const value2 = state.measurements[measurementName2].value;
    if (value === undefined || value === null
        || value2 === undefined || value2 === null) {
        value = '?';
    }
    else {
        value = (value + value2) / 2.0;
        value = formatNumber(value, textElem);
    }
    textElem.innerHTML = `${value}${unit}`;

    textElem.classList.toggle('expired', state.measurements[measurementName1].timestamp < EXPIRATION_DATE);
}

function updateTemperatureArrows(arrow, state, EXPIRATION_DATE, mode) {
    const arrowElem = exists(`arrow_${arrow.name}`);
    const textElem = exists(`text_${arrow.name}`);

    arrowElem.classList.toggle('invisible', !arrow.visibleInModes.includes(mode));
    textElem.parentElement.classList.toggle('invisible', !arrow.visibleInModes.includes(mode));

    const unit = (textElem.dataset.unit ? textElem.dataset.unit : '');
    let value = 0;
    for (let i = 0; i < arrow.temperatures.length; i++) {
        const temp = state.measurements[arrow.temperatures[i]].value;
        if (temp === undefined || temp === null) {
            value = '?';
            break;
        }
        value += temp;
    }

    if (value !== '?') {
        value /= arrow.temperatures.length;
        value = formatNumber(value, textElem);
    }
    textElem.innerHTML = `${value}${unit}`;
    textElem.classList.toggle('expired', state.measurements[arrow.temperatures[0]].timestamp < EXPIRATION_DATE);
}

function updateArrow(arrowElem, arrowData, state, EXPIRATION_DATE, mode) {
    const MIN_THICKNESS = 0.5;
    const MAX_THICKNESS = 66.66667;

    arrowElem.classList.toggle('invisible', !arrowData.visibleInModes.includes(mode));

    let value = state.measurements[arrowData.measurement].value;
    if (value === undefined || value === null) {
        arrowElem.classList.add('unknown');
        value = 1;
        return;
    }

    value = applyThreshold(value);
    value = wattTokiloWatt(value);
    value = Math.abs(value);

    arrowElem.classList.remove('unknown');
    if (value >= 0) {
        // reverse <- arrow
        if (arrowElem.getAttribute('marker-start')) {
            arrowElem.setAttribute('marker-end', arrowElem.getAttribute('marker-start'));
            arrowElem.removeAttribute('marker-start');
        }
    }
    else {
        // reverse -> arrow
        if (arrowElem.getAttribute('marker-end')) {
            arrowElem.setAttribute('marker-start', arrowElem.getAttribute('marker-end'));
            arrowElem.removeAttribute('marker-end');
        }
    }

    value = Math.abs(value);
    if (value < 0.01) {
        arrowElem.setAttribute('stroke-width', `${MIN_THICKNESS}px`);
        arrowElem.classList.add('zero');
        return;
    }

    arrowElem.classList.remove('zero');
    value = Math.max(value, 0.01); // limit for log scale
    value = (Math.log10(value + 0.3) * 17) + 10;
    value = Math.max(MIN_THICKNESS, Math.min(value, MAX_THICKNESS));
    arrowElem.setAttribute('stroke-width', `${value}px`);

    arrowElem.classList.toggle('expired', (state.measurements[arrowData.measurement].timestamp < EXPIRATION_DATE));

    /*
     * Color of the arrow based on temperature
     * TODO: temporarily we draw all arrows with the same color,
     * later we are going to color them based on some logic related to temperature
     */
    /*
     *if (arrowData.temperatures.length > 0) {
     *    const temperatures = arrowData.temperatures.map((signalName) => state.measurements[signalName].value);
     *    const temperatureAvarage = temperatures.reduce((a, b) => a + b) / temperatures.length;
     *    const roomTemp = 22;
     *    arrowElem.classList.toggle('hot', temperatureAvarage >= roomTemp);
     *    arrowElem.classList.toggle('cold', temperatureAvarage < roomTemp);
     *}
     */
}

function updateText(textElem, arrowData, state, EXPIRATION_DATE, mode) {
    textElem.parentElement.classList.toggle('invisible', !arrowData.visibleInModes.includes(mode));

    const unit = (textElem.dataset.unit ? textElem.dataset.unit : '');
    let value = state.measurements[arrowData.measurement].value;
    if (value === undefined || value === null) {
        value = '?';
    }
    else {
        value = applyThreshold(value);
        value = wattTokiloWatt(value);
        value = Math.abs(value);
        value = formatNumber(value, textElem);
    }
    textElem.innerHTML = `${value}${unit}`;

    textElem.classList.toggle('expired', state.measurements[arrowData.measurement].timestamp < EXPIRATION_DATE);
}

function applyThreshold(value) {
    const threshold = 100;
    if (Math.abs(value) < threshold) {
        return 0;
    }
    else {
        return value;
    }
}

function wattTokiloWatt(value) {
    return value / 1000.0;
}

/**
 * Page to show SVGs and Animations
 */
export default function HeatFlow() {
    /**
     * useEffect to request and activate realtime data
     */
    useEffect(() => {
        set_topics();
        initialize_heatflow_state();
        initialize_heatflow_timer(() => {
            draw_heatflow(heatflow_state);
        });
        request_sse();
    }, []);

    return (
        <>
            <main id="heatflow_page" className="no_padding svg">
                <div className="heatflow-header">
                    <div>Mode: <span id="heatflow-mode-label"></span></div>
                    <div className="legend">
                        <div className="legend-red"></div>
                        <i
                            className="a-icon boschicon-bosch-ic-air-hot"
                            style={{ 'fontSize': '32px' }}
                        />
                        <div>Hot air</div>
                    </div>
                    <div className="legend">
                        <div className="legend-blue"></div>
                        <i
                            className="a-icon boschicon-bosch-ic-air-ice"
                            style={{ 'fontSize': '32px' }}
                        />
                        <div>Cold air</div>
                    </div>
                    <div className="legend">
                        <i
                            className="a-icon boschicon-bosch-ic-flash"
                            style={{ 'fontSize': '32px' }}
                        />
                        <div>Electric power</div>
                    </div>
                </div>
                <HeatFlow_SVG />
            </main>
        </>
    );
}
