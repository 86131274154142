import React, { useEffect, useState } from 'react';
import ModeCard from '../../ModeCard';
import SignalDisplayRow from '../components/SignalDisplayRow/SignalDisplayRow';
import AutoModeCard from '../../AutoModeCard';
import { requests } from '../../../config/network';
import TimeDisplay from '../components/TimeDisplay';
import NoData from '../../NoData/NoData';
import ShowcaseBlock from '../../ShowcaseBlock';
import { ReactComponent as RangeIcon } from '../../../../images/shm/Range.svg';
import { ReactComponent as RoadIcon } from '../../../../images/shm/Road.svg';
import { ReactComponent as EnergyIcon } from '../../../../images/shm/Energy.svg';

/**
 * Display current state of Safe Home Mode dashboard
 *
 */
export default function DrivingTab({
    ComEmac_iDcLnk01,
    ComEmac_uDcLnk01,
    Com_iCmprInp,
    Com_iPtcAct,
    VehCoorn_dstRngPrdnDrvgCnd0,
    VehCoorn_dstRngPrdnDrvgCnd1,
    VehCoorn_dstRngPred,
    Com_dstOdo,
    Com_uCmprInp,
    Com_uPtcAct,
    Com_egyHvbAvl_f,
}) {
    const [ isShowCaseActive, setIsShowCaseActive ] = useState(false);
    const [ activeMode, setActiveMode ] = useState(0);
    const [ lastShowcase, setLastShowcase ] = useState(undefined);

    const [ param_RangeActual, setParam_RangeActual ] = useState(0);
    const [ param_RangeSHM, setParam_RangeSHM ] = useState(null);
    const [ param_EnergyMotion, setParam_EnergyMotion ] = useState(0);
    const [ param_EnergyThermal, setParam_EnergyThermal ] = useState(0);
    const [ param_Consumption, setParam_Consumption ] = useState();
    const [ param_TravelDistance, setParam_TravelDistance ] = useState(0);
    const [ param_TravelTime, setParam_TravelTime ] = useState();

    const [ startData, setStartData ] = useState();

    const [ previous_ComEmac_uDcLnk01_timestamp, setPrevious_ComEmac_uDcLnk01_timestamp ] = useState();
    const [ previous_Com_uCmprInp_timestamp, setPrevious_Com_uCmprInp_timestamp ] = useState();
    const [ previous_Com_uPtcAct_timestamp, setPrevious_Com_uPtcAct_timestamp ] = useState();

    /**
     * Init component
     */
    useEffect(() => {
        const savedShowcases = [
            JSON.parse(localStorage.getItem('last-shm-show-case')),
            JSON.parse(localStorage.getItem('last-auto-mode-show-case')),
            JSON.parse(localStorage.getItem('last-reference-show-case')),
        ].filter((savedShowcase) => Boolean(savedShowcase));

        const lastSavedShowcase = savedShowcases.length ? savedShowcases.reduce((latest, current) => (new Date(current.stopDate) > new Date(latest.stopDate) ? current : latest)) : null;

        setLastShowcase(lastSavedShowcase);
        setParam_RangeActual(lastSavedShowcase?.param_RangeActual);
        setParam_RangeSHM(lastSavedShowcase?.param_RangeSHM);
        setParam_EnergyMotion(lastSavedShowcase?.param_EnergyMotion);
        setParam_EnergyThermal(lastSavedShowcase?.param_EnergyThermal);
        setParam_Consumption(lastSavedShowcase?.param_Consumption);
        setParam_TravelDistance(lastSavedShowcase?.param_TravelDistance);
        setParam_TravelTime(lastSavedShowcase?.param_TravelTime);
    }, [ ]);

    /**
     * Calculate and display travel time when showcase is active
     *
     */
    useEffect(() => {
        if (!startData?.timestamp) {
            return;
        }

        const interval = setInterval(() => {
            const currentTime = Date.now();
            setParam_TravelTime(Math.floor((currentTime - startData.timestamp) / 1000));
        }, 1000);

        return () => clearInterval(interval);
    }, [ startData?.timestamp ]);

    /**
     * Calculate and display travel time when showcase is active
     *
     */
    useEffect(() => {
        if (isShowCaseActive && startData) {
            // Calculate travel distance
            const travelDistance = Com_dstOdo?.value - startData.Com_dstOdo?.value;
            setParam_TravelDistance(travelDistance);

            // Calculate range actual and range shm
            const range = startData.VehCoorn_dstRngPred?.value - travelDistance;
            setParam_RangeActual(range);
            setParam_RangeSHM(VehCoorn_dstRngPred?.value);

            // Calculate Energy motion
            const ComEmac_uDcLnk01__deltaTime = calculateDeltaTime(ComEmac_uDcLnk01.timestamp, previous_ComEmac_uDcLnk01_timestamp);
            const energyMotion = ComEmac_uDcLnk01?.value * ComEmac_iDcLnk01?.value * ComEmac_uDcLnk01__deltaTime;
            setParam_EnergyMotion(param_EnergyMotion + energyMotion);
            setPrevious_ComEmac_uDcLnk01_timestamp(ComEmac_uDcLnk01.timestamp);

            // Calculate Energy thermal
            const Com_uCmprInp__deltaTime = calculateDeltaTime(Com_uCmprInp.timestamp, previous_Com_uCmprInp_timestamp);
            const Com_uPtcAct__deltaTime = calculateDeltaTime(Com_uPtcAct.timestamp, previous_Com_uPtcAct_timestamp);
            const energyCompressor = Com_uCmprInp?.value * Com_iCmprInp?.value * Com_uCmprInp__deltaTime;
            const energyPTC = Com_uPtcAct?.value * Com_iPtcAct?.value * Com_uPtcAct__deltaTime;
            const energyThermal = energyCompressor + energyPTC;
            setParam_EnergyThermal(param_EnergyThermal + energyThermal);
            setPrevious_Com_uCmprInp_timestamp(Com_uCmprInp.timestamp);
            setPrevious_Com_uPtcAct_timestamp(Com_uPtcAct.timestamp);

            // Calculate Consumption
            const consumption = travelDistance ? ((startData.Com_egyHvbAvl_f?.value - Com_egyHvbAvl_f?.value) / travelDistance * 100) : null; // TODO Check late with Michael
            setParam_Consumption(consumption);
        }
    }, [
        isShowCaseActive,
        startData,
        ComEmac_iDcLnk01,
        ComEmac_uDcLnk01,
        Com_iCmprInp,
        Com_iPtcAct,
        Com_dstOdo,
        Com_uCmprInp,
        Com_uPtcAct,
        Com_egyHvbAvl_f,
    ]);

    function calculateDeltaTime(actualTimestamp, previousTimestamp) {
        const milisecToSec = 1000;
        const secToHour = 3600;
        return Number(actualTimestamp - previousTimestamp) / milisecToSec / secToHour;
    }

    function round(number) {
        if (number === null || number === undefined) {
            return number;
        }

        return parseFloat(number.toFixed(1));
    }

    const handleSelectedMode = (mode, range) => {
        const emittedRange = mode === 0 ? 0 : range;
        setActiveMode(mode);
        postSelectedMode(mode, emittedRange);

        if (!isShowCaseActive && mode !== 0) {
            handleStartShowCase();
        }
    };

    function postSelectedMode(mode, range) {
        const carId = localStorage.getItem('car_id');

        if (carId === undefined || carId === null) {
            console.error('No car id available');
            return;
        }

        fetch(requests.user_interaction_cvem_shm, {
            'method': 'POST',
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token'),
            },
            'body': JSON.stringify({ 'mode': Number(mode), 'range': Number(range), 'carId': Number(carId) }),
        })
            .then((response) => response.json().then(async (data) => {
                if (response.status === 201) {
                    console.log('Post shm ok', data, response);
                }
                else {
                    console.error('Post shm NOT ok', data, response);
                }
            }))
            .catch((error) => {
                console.error(error);
            });
    }

    function handleClickShowCase() {
        if (!isShowCaseActive) {
            handleStartShowCase();
        }
        else {
            handleStopShowCase();
        }
    }

    function handleStartShowCase() {
        const actualDate = new Date();

        // Set previous timestamps
        setPrevious_ComEmac_uDcLnk01_timestamp(actualDate);
        setPrevious_Com_uCmprInp_timestamp(actualDate);
        setPrevious_Com_uPtcAct_timestamp(actualDate);

        // Set start data
        setStartData({
            'ComEmac_iDcLnk01': ComEmac_iDcLnk01,
            'ComEmac_uDcLnk01': ComEmac_uDcLnk01,
            'Com_iCmprInp': Com_iCmprInp,
            'Com_iPtcAct': Com_iPtcAct,
            'VehCoorn_dstRngPrdnDrvgCnd0': VehCoorn_dstRngPrdnDrvgCnd0,
            'VehCoorn_dstRngPrdnDrvgCnd1': VehCoorn_dstRngPrdnDrvgCnd1,
            'VehCoorn_dstRngPred': VehCoorn_dstRngPred,
            'Com_dstOdo': Com_dstOdo,
            'Com_uCmprInp': Com_uCmprInp,
            'Com_uPtcAct': Com_uPtcAct,
            'Com_egyHvbAvl_f': Com_egyHvbAvl_f,
            'timestamp': actualDate,
        });

        setParam_TravelDistance(0);
        setParam_EnergyMotion(0);
        setParam_EnergyThermal(0);
        setIsShowCaseActive(true);
    }

    function handleStopShowCase() {
        const result = {
            'param_RangeActual': param_RangeActual,
            'param_RangeSHM': param_RangeSHM,
            'param_EnergyMotion': param_EnergyMotion,
            'param_EnergyThermal': param_EnergyThermal,
            'param_Consumption': param_Consumption,
            'param_TravelDistance': param_TravelDistance,
            'param_TravelTime': param_TravelTime,
            'startShowcase': startData.timestamp,
            'stopShowcase': new Date(),
            'overallConsumedEnergy': startData.Com_egyHvbAvl_f.value - Com_egyHvbAvl_f.value,
        };

        saveShowcase(result);
        setLastShowcase(result);
        setStartData(null);
        uncheckAllModeToggles();
        setIsShowCaseActive(false);
    }

    function saveShowcase(showcaseData) {
        switch (activeMode) {
            case 1:
            case 2:
            case 3: {
                const showcaseWithMode = { ...showcaseData, 'shmMode': activeMode };
                localStorage.setItem('last-shm-show-case', JSON.stringify(showcaseWithMode));
                break;
            }
            case 4:
                localStorage.setItem('last-auto-mode-show-case', JSON.stringify(showcaseData));
                break;
            default:
                localStorage.setItem('last-reference-show-case', JSON.stringify(showcaseData));
        }
    }

    function uncheckAllModeToggles() {
        [ ...document.querySelectorAll('input.mode_toggle:checked') ].forEach((element) => {
            element.checked = false;
        });
        const mode = 0;
        const range = 0;
        handleSelectedMode(mode, range);
    }

    return (
        <div id="shm">
            <div className="side">
                <ModeCard number={1} limit={80} temperature={2} selectedModeCallback={handleSelectedMode} />
                <ModeCard number={2} limit={60} temperature={4} selectedModeCallback={handleSelectedMode} />
                <ModeCard number={3} limit={50} temperature={6} selectedModeCallback={handleSelectedMode} />
                <AutoModeCard
                    number={4}
                    minRange={VehCoorn_dstRngPrdnDrvgCnd0?.value}
                    maxRange={VehCoorn_dstRngPrdnDrvgCnd1?.value}
                    activeMode={activeMode}
                    changeCallback={handleSelectedMode}
                />
            </div>

            <div className="a-box -floating-shadow-s side info-card showcase-card">
                <div className="showcase-header">
                    {isShowCaseActive
                        ? <span className="showcase-header-title">Recording values in progress...</span>
                        : <span className="showcase-header-title">Recorded values</span>}
                    <ShowcaseButton />
                </div>
                <div className="showcase-content">
                    {isShowCaseActive || lastShowcase ? <ShowcaseData /> : <NoData title="No previous showcase" />}

                </div>

            </div>
        </div>
    );

    function ShowcaseData() {
        return (
            <>
                <ShowcaseBlock Icon={RangeIcon} title="Range">
                    <SignalDisplayRow name="km normal mode">{round(param_RangeActual)}</SignalDisplayRow>
                    <SignalDisplayRow name="km safe home mode">{round(param_RangeSHM)} </SignalDisplayRow>
                </ShowcaseBlock>

                <ShowcaseBlock Icon={EnergyIcon} title="Energy">
                    <SignalDisplayRow name="Wh motion">{round(param_EnergyMotion) || 0}</SignalDisplayRow>
                    <SignalDisplayRow name="Wh thermal">{round(param_EnergyThermal) || 0}</SignalDisplayRow>
                    <SignalDisplayRow name="Wh/100km "> {(param_Consumption === null && isShowCaseActive) ? (<span style={{ 'color': 'grey', 'fontSize': '0.5em' }}>calculating</span>) : (round(param_Consumption) || 0)}</SignalDisplayRow>
                </ShowcaseBlock>

                <ShowcaseBlock Icon={RoadIcon} title="Travel">
                    <SignalDisplayRow name="km distance">{round(param_TravelDistance)}</SignalDisplayRow>
                    <SignalDisplayRow name={`:${String(param_TravelTime % 60).padStart(2, '0')} duration`}><TimeDisplay seconds={param_TravelTime} /></SignalDisplayRow>
                </ShowcaseBlock>
            </>
        );
    }

    function ShowcaseButton() {
        return (
            <div>
                <button type="button" className="a-button a-button--primary" style={{ 'paddingLeft': '1em' }} onClick={handleClickShowCase}>
                    <span className="a-button__label">{isShowCaseActive ? 'Stop recording' : 'Start new recording'}</span>
                </button>
            </div>
        );
    }
}

